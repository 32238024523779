import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import { FaUser } from "react-icons/fa";
import { Stepper, Step, Button } from "@material-tailwind/react";
import { IoIosCloudDone } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosCard } from "react-icons/io";

import { GiConfirmed } from "react-icons/gi";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import PackageDetails from "./components/PackageDetails";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [completedSteps, setCompletedSteps] = useState([]);

  const [error, setError] = useState(null);

  let plan = localStorage.getItem("plan");
  let netOptions = localStorage.getItem("netOption");
  let addOns = JSON.parse(localStorage.getItem("addOn"));
  let addonNames = addOns?.map((addon) => addon.name);

  const selectedPackage = {
    plan: plan || "",
    addOn: addonNames || [],
    strategy: netOptions || "",
  };

  const [customerResponse, setCustomerResponse] = useState(false);

  useEffect(() => {
    if (customerResponse) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/api/create-payment-intent`,
          {
            package: selectedPackage,
            customer: customerResponse?.customer?.id,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          setClientSecret(response.data.clientSecret);
        })
        .catch((error) => {
          console.error("Error fetching client secret:", error);
        });
    }
  }, [customerResponse]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    paymentMethodCreation: "manual",
  };

  let selectedPlan = "";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    coupon: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newError = null;

    // if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
    //   newError = "Invalid email format";
    //   toast.error(newError);
    // }

    setFormData({
      ...formData,
      [name]: value,
    });

    setError(newError);
  };

  const handleSubmitCustomer = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/api/create-customer`,
          formData
        )
        .then((res) => {
          setCustomerResponse(res?.data);
          toast.success(res?.data?.message);
          setActiveStep(1); // Move to the next step
        })
        .catch((err) => {
          toast.error("Error creating customer");
        });
    } catch (error) {
      console.error("Error creating Stripe customer:", error);
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);
  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} closeOnClick />

      <PackageDetails />

      <div className="flex flex-col justify-center card-custom-body mt-4">
        {activeStep === 0 && (
          <div className="form-container grid lg:grid-col-3 active mt-8 gap-4 transition-transform duration-500">
            <form
              className="customer-form bg-white shadow-md rounded-2xl"
              onSubmit={handleSubmitCustomer}
            >
              <label className="block text-gray-700 font-medium text-sm">
                First Name:
                <input
                  className="shadow appearance-none border rounded w-full py-12 px-3 text-gray-700 mt-2 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Enter your First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label className="block text-gray-700 text-sm font-medium mb-2">
                Last Name:
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Enter your Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Email:
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Enter your Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Coupon:
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Enter your Coupon Here"
                  name="coupon"
                  value={formData.coupon}
                  onChange={handleInputChange}
                />
              </label>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="stripe-button font-medium text-sm text-white py-2 px-4 rounded-e-lg"
                >
                  Create Customer
                </button>
              </div>
            </form>
          </div>
        )}

        {activeStep === 1 && (
          <div className="form-container">
            {clientSecret && customerResponse && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  clientSecret={clientSecret}
                  coupon={formData.coupon}
                  customer={customerResponse.customer}
                />
              </Elements>
            )}
          </div>
        )}
      </div>
      {activeStep === 2 && (
        <div className="successfull-box-custom">
          <IoIosCloudDone />
          <div className="successfull-box-content-custom">
            <h1 className="text-4xl text-center">
              Your order has been placed successfully
            </h1>
            <h6 className="text-2xl text-center">Thank You For Choosing Us</h6>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
