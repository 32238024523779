import react, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, selectItems } from "../../slices/cartSlice";
import strip from "../../../src/Assets/Images/strip.png"
import axios from "axios";
import "./PaymentForm.css"

export const PaymentForm = ({ discount, finalPrice, clientSecret, Elements, options, stripePromise, CheckoutForm, customerResponse, logo, priceIds, selectPackageRef }) => {

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [priceId, setPriceId] = useState()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [coupon, setCoupon] = useState('')
  const selectedItems = useSelector(selectItems)
  const [couponMessage, setCouponMessage] = useState("")
  const [couponData, setCouponData] = useState(null)

  useEffect(()=> {
    if(selectedItems?.length == 1){
      setCouponData(null)
    }
  }, [selectedItems, selectedItems?.length])

  const dispatch = useDispatch()
  let priceIdArr = []
  useEffect(() => {
    const addons = JSON.parse(localStorage.getItem("addons")) || [];
    const packages = JSON.parse(localStorage.getItem("packages")) || [];
    let packagePrice = selectedItems?.find((itm) => itm?.package_fee)?.package_fee || 0
    // let packagePrice = selectedItems?.[1]?.package_fee || 0;
    // package Price id

    let matchingPackageArr = packages.filter((pkg) => pkg.package_name === selectedItems?.[0]?.package_name)
    console.log("matchingPackageArr: ", matchingPackageArr)
    console.log("selectedItems: ", selectedItems)
    let matchingPackageFee = selectedItems?.find(item => item?.package_fee);
    if (!matchingPackageFee) {
      console.log("No matching package fee found");
    } else {
      let firstPrice = matchingPackageArr[0]?.monthlyRetainer?.filter((retainer) => retainer?.monthly_retainer == matchingPackageFee?.package_fee);
      
      console.log("firstPrice: ", firstPrice);
      
      firstPrice = firstPrice && firstPrice.length > 0 ? firstPrice[0]?.priceId : "";
      
      priceIdArr.push(firstPrice);
    }
    

    // const addonsArr = selectedItems?.[2]?.selectedAddons || [];
    const addonsArr = selectedItems
    ?.map((itm) => itm?.selectedAddons)
    ?.filter(Boolean) || [];
    const newArr = addonsArr[0]?.flatMap((addonItem) => {
  
      const matchingPackage = addons.find(addon => addon.package_name === selectedItems?.[0]?.package_name);

      if (matchingPackage) {
        return matchingPackage.addons
          ?.map((addon, index) => {
            if (addon.addons === addonItem) {
              priceIdArr.push(matchingPackage.additionalFee?.[index]?.priceId);
              return matchingPackage.additionalFee?.[index]?.additionalFee || 0;
            }
          })
          .filter(fee => fee !== undefined);
      }

      return [];
    });

    if (priceIdArr.length > 0) {
      localStorage.setItem("priceIds", JSON.stringify(priceIdArr))
    }


    // get trump price
    let trumpPrice = selectedItems?.map((itm)=>itm.trumpPrice)?.filter(Boolean)
    trumpPrice = trumpPrice[0]?.replace(/[$,]/g, '');

    const totalSum = newArr
      ?.map(fee => Number(fee?.replace(/[^0-9.-]+/g, "")))
      .reduce((acc, curr) => acc + curr, 0);

    packagePrice = packagePrice && packagePrice?.replace(/[,$]/g, '');
    setSubTotal(parseInt(totalSum != undefined ? totalSum : 0) + parseInt(packagePrice != undefined ? packagePrice : 0) + parseInt(trumpPrice != undefined ? parseInt(trumpPrice) : 0)); 
    setTotal(parseInt(totalSum != undefined ? totalSum : 0) + parseInt(packagePrice != undefined ? packagePrice : 0) + parseInt(trumpPrice != undefined ? parseInt(trumpPrice) : 0)); 
  
  }, [selectedItems]);

  const handleCoupon = async (value) => {
    setCoupon(value);
    if (value == "") {
      return setCouponMessage("")
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/api/validate-coupon`,
        {
          couponCode: value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        });
      setCouponData(response?.data?.coupon)
      if (response.data.valid) {
        setCouponMessage(`Coupon applied: ${response.data.coupon.id}`)
      }
    } catch (error) {
      setCouponData(null)
      setCouponMessage(error.response?.data?.message || 'An error occurred while validating the coupon.')
    }

  };
  useEffect(()=> {
    renderReceipt(selectedItems)
  }, [selectedItems, selectedItems[0]?.package_name])


  const renderReceipt = () => {
    const formatPrice = (price) => {
      const cleanPrice = parseFloat(price?.replace(/[$,]/g, '')); // Remove any existing $ or commas and convert to a float
      if (isNaN(cleanPrice)) return "$0.00"; // Handle invalid or undefined prices
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cleanPrice); // Format as USD with commas
    };
    let addonsPrice = []
  
    const packageName = selectedItems?.find((itm) => itm?.package_name)?.package_name;
    const packageFee = selectedItems?.find((itm) => itm?.package_fee)?.package_fee;

    const trumpPrice = selectedItems?.find((itm) => itm?.trumpPrice)?.trumpPrice
  
    return (
      <div>
        {packageName && packageFee && (
          <div className="flex justify-between py-5">
            <p className="text-[18px] leading-[5px] text-xs md:text-base">{packageName} - Reoccurring</p>
            <p className="text-[18px] leading-[5px] text-xs md:text-base">{formatPrice(packageFee)}</p>
          </div>
        )}
  
        {selectedItems?.map((item, index) => (
          <div key={index}>
            {item.trump != "Monthly" && (
              <>
                {item.trump && (
                  <div className="flex justify-between py-5">
                    <p className="text-[18px] leading-[5px] text-xs md:text-base">{item.trump} - Reoccurring</p>
                    <p className="text-[18px] leading-[5px] text-xs md:text-base">
                      {formatPrice(item.trumpPrice)}
                    </p>
                  </div>
                )}
              </>
            )}
            
  
            {item.selectedAddons?.map((addon, addonIndex) => {
              // Fetch addons from localStorage
              let addons = JSON.parse(localStorage.getItem("addons"));
  
              // Filter the addons based on package name
              let packageAddons = addons?.filter((itm) => itm.package_name === packageName)[0];
  
              // Check if packageAddons exists before filtering
              let filteredAddon = packageAddons?.addons?.find((obj) => obj.addons === addon);
              
              // Get the corresponding price if the addon is found
              let addonPrice = packageAddons?.additionalFee?.[
                filteredAddon ? packageAddons.addons.indexOf(filteredAddon) : -1
              ]?.additionalFee;
              
              addonsPrice.push(addonPrice)
              return (
                <div key={addonIndex} className="flex justify-between py-5">
                  <p className="text-[18px] leading-[5px] text-xs md:text-base">{addon} - One time</p>
                  <p className="text-[18px] leading-[5px] text-xs md:text-base">
                    {formatPrice(addonPrice || "0")} {/* Display the price of the addon or "0" */}
                  </p>
                </div>
              );
            })}
          </div>
        ))}
        <hr className="mt-4 border-t border-[#0d1e32]" />
          
          <div className="flex justify-between py-5">
            <p className="text-[18px] leading-[5px]">Reoccurring Total:</p>
            <p className="text-[18px] leading-[5px]">
              ${(
                (parseFloat(packageFee?.replace(/[$,]/g, "")) || 0) +
                (parseFloat(trumpPrice?.replace(/[$,]/g, "")) || 0)
              ).toLocaleString()}
            </p>
          </div>
          <div className="flex justify-between py-5">
            <p className="text-[18px] leading-[5px]">One-Time Total:</p>
            <p className="text-[18px] leading-[5px]">
              ${addonsPrice
                ?.map((price) => parseFloat(price.replace(/[$,]/g, "")) || 0)  // Remove $ and commas, convert to number
                ?.reduce((acc, curr) => acc + curr, 0)                          // Sum all prices
                ?.toLocaleString()}     
            </p>
          </div>

        {/* )} */}
      </div>
    );
  };
  

  console.log("selectedItems: ", selectedItems)
  return(
    <>
      <div className={` ${selectedItems?.find((itm) => itm?.finalConfirm)?.finalConfirm ? "hidden" : "overlay"}`}></div>
      <div className={`relative ${selectedItems?.find((itm) => itm?.finalConfirm)?.finalConfirm ? "" : "disable-background"}`}>
        
        <div className="bg-white  text-[#0D1E32]">

              <div ref={selectPackageRef} className="flex items-center gap-3">
                <h2 className="font-bold text-[30px] leading-[37.5px]">
                  Order Details
                </h2>
                
              </div>

              <div className="">
                {renderReceipt(selectedItems)}
              </div>

              <div className="mt-4">
                
                
            
            <div className="mt-4">
              <div className="flex justify-between py-5">
                <p className="font-bold text-[24px] leading-[5px]">Today's Total</p>
                <p className="font-bold text-[24px] leading-[5px]">
                {couponData ? (
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    total - (subTotal * couponData.percent_off) / 100
                  )
                ) : (
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)
                )}

                </p>
              </div>
            </div>
            <hr className="mt-4 font-bold border-t border-[#0d1e32] w-[80%] mx-auto" />
          </div>
        </div>

        {/* form */}

        <form>
          <div className="bg-white py-6 text-[#0D1E32]">
            <h2 className="font-bold text-[26px] leading-[37.5px] mb-6">
              Enter payment details
            </h2>
            <div className="mb-4">
              <label className="block font-normal text-[18px] leading-[28px] text-[#0D1E32] mb-2">
                First Name:
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Enter your name..."
                className="w-full p-3 rounded-lg bg-[#F8F6F2] border border-[#0D1E32] text-[18px] leading-[28px] text-[#0D1E32] focus:outline-none"
              />
            </div>

            <div className="mb-4">
              <label className="block font-normal text-[18px] leading-[28px] text-[#0D1E32] mb-2">
                Email:
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter your Email..."
                className="w-full p-3 rounded-lg bg-[#F8F6F2] border border-[#0D1E32] text-[18px] leading-[28px] text-[#0D1E32] focus:outline-none"
              />
            </div>

            <div className="mb-4">
              <label className="block font-normal text-[18px] leading-[28px] text-[#0D1E32] mb-2">
                Phone Number:
              </label>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="+1 123 456 789"
                className="w-full p-3 rounded-lg bg-[#F8F6F2] border border-[#0D1E32] text-[18px] leading-[28px] text-[#0D1E32] focus:outline-none"
              />
            </div>
            
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  clientSecret={clientSecret}
                  coupon=""
                  customer={customerResponse}
                  priceIds={priceIdArr}
                  name={name}
                  email={email}
                  phone={phone}
                  couponCode={coupon}
                />
              </Elements>
            )}

            <p className="mt-4 text-[#96A2AF] font-normal text-[18px] leading-[28px]">
              By confirming your subscription, you are allowing us to
              instantly charge you for future payments in accordance with our
              terms. You can always cancel your subscription.
            </p>
          </div>
        </form>

        

    
        <div className="w-full flex justify-center mt-[100px]">
          <img
            src={strip}
            alt="BANEDIGITAL Logo"
            className="h-auto  float-end w-48" // Adjust width and height as needed
          />
        </div>
      </div>
    </>
  )
}