import './components/styles/App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import Home from './Home';
import Checkout from './Checkout';
import Success from './Success';
import PaymentDetail from './PaymentDetail';


function App() {
    return (
        <Provider store={store}>
        <HashRouter hashType="noslash">
            <div className='container'>
                <Routes>
                    <Route exact path="/" element= {<PaymentDetail/>} />
                    <Route path="/checkout" element= {<Checkout/>} />
                    <Route path="/success" element= {<Success/>} />
                </Routes>
            </div>
        </HashRouter>
        </Provider>
    );
}

export default App;
