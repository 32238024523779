import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "./styles/CheckoutForm.css";
import { ToastContainer, toast } from "react-toastify";

export default function CheckoutForm({ clientSecret, coupon, customer, name, email, phone, couponCode }) {
  const stripe = useStripe();
  const elements = useElements();
  const priceIds = JSON.parse(localStorage.getItem("priceIds"));
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentIntId, setPaymentIntId] = useState("");
  useEffect(() => {
    if (!stripe) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        setPaymentIntId(paymentIntent.id);
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      })
      .catch((err) => console.log("ERRRRRRRR: ", err));
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(name == "" || email == "" || phone == ""){
      return toast.error("Please enter all details");
    }
    let iniObj = {};
    setIsLoading(true)
    let companyDomain = localStorage.getItem("companyDomain")
    let plan = localStorage.getItem("plan");
    let netOptions = localStorage.getItem("netOptions");
    let addOns = JSON.parse(localStorage.getItem("addOn")) || ['Content Calendar Management'];
    let addonNames = addOns?.map((addon) => addon.name);

    const selectedPackage = {
      plan: plan || "",
      addOn: addonNames || [],
      strategy: netOptions || "",
    };

    if (!stripe || !elements) {
      return;
    }
    const { errorx, successx } = await elements.submit();
    await stripe.createPaymentMethod({ elements }).then(async (res) => {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/api/create-subscription`,
          {
            paymentMetId: res?.paymentMethod?.id,
            customerId: customer.id,
            priceIds: priceIds,
            mandate: res.paymentMethod,
            type: res.paymentMethod.type,
            name: name,
            email: email,
            phone: phone,
            companyDomain: companyDomain,
            couponCode: couponCode,
            paymentIntId: paymentIntId
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          toast.success(res?.data?.message);
          setIsLoading(false)
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null });
          iniObj.items = res?.data?.subscription?.items?.data?.map((item, index) => ({
            item_id: item.id || "",                      
            item_name: item.name || "",                 
            affiliation: item.affiliation || "", 
            coupon: item.coupon || "",                
            discount: item.discount || 0,                           
            index: index,                                           
            item_brand: item.brand || "",              
            item_category: item.category || "",     
            item_category2: item.category2 || "",  
            item_category3: item.category3 || "",  
            item_category4: item.category4 || "",  
            item_category5: item.category5 || "",  
            item_list_id: item.list_id || "",        
            item_list_name: item.list_name || "",  
            item_variant: item.variant || "",        
            location_id: item.location_id || "", 
            price: item.price || 0,                                 
            quantity: item.quantity || 1                            
          }));
          const [first_name, last_name = ""] = name.split(" ");
          iniObj.user_data = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone
          }
          window.dataLayer.push(iniObj);
          setTimeout(()=> {
            window.location.reload();
          }, 2000)
        })
        .catch((err) => {
          toast.error("Error creating subscription!")
          setIsLoading(false)
        });
    });
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className="form-container grid lg:grid-col-3 active mt-8 gap-4 transition-transform duration-500">
      <ToastContainer position="top-right" autoClose={2000} closeOnClick />

      <form id="payment-form " onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          className="pay-button"
          style={{ marginTop: "10px" }}
          disabled={isLoading || !stripe || !elements}
          type="submit"
          id="submit"
          onClick={handleSubmit}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      </form>
    </div>
  );
}
