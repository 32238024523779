import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function PackageDetails() {
  useEffect(() => {
    getPrices();
  }, []);

  const [prices, setPrices] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [selectedAddonIndexes, setSelectedAddonIndexes] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // const plan = queryParams.get("plan");

  // useEffect(() => {
  //   if (plan && prices.length > 0) {
  //     let packageData;
  //     switch (plan) {
  //       case "Starter":
  //         packageData = prices.find(
  //           (price) => price.name === "Starter Package"
  //         );
  //         break;
  //       case "Premium":
  //         packageData = prices.find(
  //           (price) => price.name === "Premium Package"
  //         );
  //         break;
  //       case "Standard":
  //         packageData = prices.find(
  //           (price) => price.name === "Standard Package"
  //         );
  //         break;
  //       default:
  //         break;
  //     }

  //     if (packageData) {
  //       setActivePlanId({ id: packageData.productId, name: packageData.name });
  //       localStorage.setItem("plan", packageData.name);  
  //     }
  //   }
  // }, [plan, prices]);

  const getPrices = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/api/getPrices`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPrices(res?.data?.prices);
      setAddOns(res?.data?.addOns);
    } catch (error) {
      toast.error("Prices not found!");
    }
  };

  const addons = addOns?.map((addon) => {
    const {
      name,
      prices,
      productId,
      createdAt,
      updatedAt,
      __v,
      _id,
      ...otherProperties
    } = addon;

    return {
      name,
      prices,
    };
  });

  const details = prices?.map((price) => {
    let activeProjectsText = "1 Active Project"; // Default to singular
    if (price?.name === "Standard Package") {
      activeProjectsText = "2 Active Projects"; // For Standard Package
    } else if (price?.name === "Premium Package") {
      activeProjectsText = "3 Active Projects"; // For Premium Package
    }

    return {
      id: price?.productId,
      name: price?.name,
      price: price?.prices[0]?.price, // Assuming the first price is displayed
      usp: [
        {
          name: `${activeProjectsText}`,
        },
        {
          name: "Dedicated Creative Lead",
        },
        {
          name: "Dedicated Project Manager",
        },
        {
          name: "Quality Assurance Team",
        },
        {
          name: "Website Design",
        },
        {
          name: "Logos & Branding",
        },
        {
          name: "Presentation & Print",
        },
      ],
      addons: addons,
      netOptions: price?.prices?.map((item, index) => {
        let discount = 0;
        if (item?.options === "NET_15") {
          discount = 4;
        } else if (item?.options === "PAY_UPFRONT") {
          discount = 8;
        }
        return {
          option: item?.options,
          discount: discount,
        };
      }),
    };
  });

  const [activePlanId, setActivePlanId] = useState(null);

  const handleAddonClick = (addon, index) => {
    const price = addon.prices[0]?.price;
    if (selectedAddonIndexes.some((item) => item.index === index)) {
      setSelectedAddonIndexes(
        selectedAddonIndexes.filter((item) => item.index !== index)
      );
      localStorage.setItem(
        "addOn",
        JSON.stringify(
          selectedAddonIndexes.filter((item) => item.index !== index)
        )
      );
    } else {
      setSelectedAddonIndexes([
        ...selectedAddonIndexes,
        { name: addon.name, index: index, price: price },
      ]);
      localStorage.setItem(
        "addOn",
        JSON.stringify([
          ...selectedAddonIndexes,
          { name: addon.name, index: index, price: price },
        ])
      );
    }
  };

  // Handle radio inputs
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (option, index, id) => {
    localStorage.setItem("netOption", option.option);
    setSelectedOption({
      index: index,
      discount: option.discount,
      name: option.option,
    });

    const ulElement = document.getElementById("radio-btns");

    // Get all <li> elements within the <ul>
    const liElements = ulElement?.querySelectorAll("li");

    // Remove the class from each <li> element
    liElements?.forEach((li) => {
      li.classList.remove("br-black");
    });
    const liElement = document.getElementById(id);
    liElement?.classList.add("br-black");
  };

  const handlePlanClick = (name, id) => {
    localStorage.setItem("plan", name);
    setActivePlanId({
      id: id,
      name: name === activePlanId?.name ? null : name,
    });
  };

  const selectedAddonsTotalPrice = selectedAddonIndexes.reduce(
    (total, addon) => total + addon.price,
    0
  );

  const getDetailsSection = () => {
    if (!activePlanId) return null;

    const selectedPlan = details.find((plan) => plan.id === activePlanId?.id);
    const totalPrice =
      selectedAddonIndexes?.length > 0
        ? selectedAddonsTotalPrice + selectedPlan?.price
        : selectedPlan?.price;
    const discountedPrice = selectedOption
      ? totalPrice * (1 - selectedOption?.discount / 100)
      : totalPrice;

    return (
      <section
        key={`details-${activePlanId?.id}`}
        className={
          "details-section grid lg:grid-col-3 active mx-10 mt-8 poppins  gap-4 transition-transform duration-500 "
        }
      >
        <div className="package-details px-8  shadow-black-500 bg-[#456494]  pt-4  text-white lg:col-span-2  shadow-md rounded-2xl">
          <div className="plan-detail-header flex justify-between mb-8 ">
            <h2 className="text-2xl font-bold  ">{selectedPlan?.name}</h2>
            <h2 className="text-2xl font-bold">
              ${discountedPrice?.toFixed(2)}/month
            </h2>
          </div>
          <p className="text-start mb-8 text-xl font-medium">
            {selectedPlan?.details}
          </p>
          <ul className="text-start mb-8 font-medium list-disc ml-8">
            {selectedPlan?.usp.map((usp, index) => (
              <li className="mb-2" key={index}>
                {usp.name}
              </li>
            ))}
          </ul>
          {selectedPlan?.addons && (
            <div className="mb-8">
              <h3 className="text-start font-semibold mb-4 ">
                {" "}
                Premium Add-ons
              </h3>
              <ul className="flex justify-start add-ons-list gap-4 cursor-pointer">
                {selectedPlan?.addons?.map((addon, index) => (
                  <li
                    className={`bg-[#b28759] rounded-lg border-2 border-transparent p-4 w-1/2 flex justify-between ${
                      selectedAddonIndexes.some(
                        (item) =>
                          item.index === index &&
                          item.price === addon.prices[0]?.price
                      )
                        ? " br-black "
                        : ""
                    }`}
                    key={index}
                    onClick={() => handleAddonClick(addon, index)}
                  >
                    <div>{Object.values(addon)[0]}</div>
                    <div>${addon.prices[0]?.price}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {selectedPlan?.netOptions && (
            <div className="mb-8">
              <h3 className="text-start mb-4 font-semibold">Net Options:</h3>
              <ul
                className="flex justify-between gap-4  radio-btns"
                id="radio-btns"
              >
                {selectedPlan?.netOptions?.map((option, index) => (
                  <li
                    key={index}
                    className="bg-[#b28759] p-4 lg:w-full md:w-full sm:w-full rounded-lg border-2 border-transparent cursor-pointer "
                    id={`option${index}`}
                    onClick={() =>
                      handleOptionChange(option, index, "option" + index)
                    }
                  >
                    <div>
                      <div className="flex justify-between ">
                        <h3>{`${
                          option.option === "NET_30"
                            ? "Net 30"
                            : option.option === "NET_15"
                            ? "Net 15"
                            : "Pay Upfront"
                        }

                          `}</h3>
                        <span>${option.discount}%</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    );
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
        handleOptionChange({option: 'PAY_UPFRONT', discount: 8}, 2, "option2")
        localStorage.setItem("netOption", "PAY_UPFRONT");  
    }, 4000);

    return () => clearTimeout(timeoutId); // Cleanup function to clear the timeout
  }, []);

  return (
    <>
      <section className="plan-options mx-10  grid lg:grid-cols-3 gap-4 poppins mt-8 ">
        {details?.map((plan) => {
          const { id, name, price } = plan;
          return (
            <div
              key={id}
              className={`text-center cursor-pointer plans border  rounded-2xl  ${
                activePlanId?.id === id ? "bg-blue text-white" : ""
              }`}
              onClick={() => handlePlanClick(name, id)}
            >
              <div className=" my-4">
                <div className="flex justify-between mx-8 mb-4">
                  <h3 className="font-semibold">{name}</h3>
                  <span className="font-semibold">${price}/month</span>
                </div>
                <div className="flex justify-center"></div>
              </div>
            </div>
          );
        })}
      </section>
      {getDetailsSection()}
    </>
  );
}

export default PackageDetails;
