import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectItems } from '../../slices/cartSlice';
import { SecondPage } from './SecondPage';
import arrow from '../../Assets/Images/brown-arrow.png'

export const FirstPage = ({ choosePackage, handlePackage, setpkgSelect, pkgSelect, packages, selectPackageRef }) => {

  const [openAccordion, setOpenAccordion] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [imageShow, setImageShow] = useState(true);
  const selectedItems = useSelector(selectItems);


  useEffect(() => {
    if (choosePackage) {
      setImageShow(true); // Show images
    }
  }, [choosePackage]);

  useEffect(()=> {
    let allPackages = JSON.parse(localStorage.getItem("packages"));


    const allPlatforms = allPackages?.map(pkg => {
      return {
        packageName: pkg.package_name,
        platforms: pkg.platforms?.map(platform => platform.platform_name)
      };
    });

    setPlatforms(allPlatforms)

  }, [selectedItems[0]?.package_name, openAccordion])


 const imgToggle=()=>{
 
  setImageShow(!imageShow)
 }
  // Example API call (commented out)

  return (
    <>
    {/* <div className={` ${selectedItems?.find((itm) => itm?.finalConfirm)?.finalConfirm ? "overlay" : "hidden"}`}></div> */}
      <div className="pkg flex flex-col justify-center items-center">
        <h1 className=" mr-auto text-white  font-semibold text-[26px] leading-[28px] mb-5">
          Select Package
        </h1>

        {/* Accordion Section */}
        <div className="w-full flex flex-wrap justify-between gap-4">
        {packages
          ?.sort((a, b) => {
            if (a.package_name === "All-In-One Bundle") return -1;
            if (b.package_name === "All-In-One Bundle") return 1;
            return 0;
          })?.map((item, index) => (
            <div
              key={index}
              className={`w-full xl:w-[48%] max-h-full border border-white rounded-lg overflow-hidden bg-white`}
            >
              <div
                className={`cursor-pointer h-full relative flex justify-between items-center border rounded-lg overflow-hidden p-3 py-1 md:py-3 ${choosePackage === item?.package_name
                  ? "bg-[#B18659] text-white"
                  : "bg-[#192C47] border border-white text-white"
                }`}
                onClick={() => handlePackage(item?.package_name)}
              >
                

                <p className={`pl-[32px] ${openAccordion === index ? 'font-bold' : 'font-semibold'}`}>
                  {item?.package_name}
                </p>
              </div>

        
            </div>
          ))}
        </div>
        {selectedItems?.length > 0 ? null : (
          <img className='absolute top-40 right-[-30px] z-50  hidden lg:block' src={arrow} height={100} width={100}/>
        )}

        {!selectedItems || selectedItems.length === 0 ? null : <SecondPage imgToggle={imgToggle} imageShow={imageShow} selectPackageRef={selectPackageRef}/>}
      </div>
    </>
  );
};
