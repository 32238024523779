import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Success = () => {
    const hashParams = new URLSearchParams(window.location.search);
    const paymentIntent = hashParams.get('payment_intent');


    const successParams = window.location.hash.substring(window.location.hash.indexOf('#/success') + '#/success'.length + 1);
    const successParamsArray = successParams.split('&');
    const successParamsObj = {};
    successParamsArray.forEach(param => {
        const [key, value] = param.split('=');
        successParamsObj[key] = value;
    });

    const { customerId, coupon } = successParamsObj;

    const selectedPackage = {
        name: "Standard",
        addOn: ["Content Calendar Management", "Content Strategy"],
        strategy: "NET_15"
    }

    const [subscriptionCreated, setSubscriptionCreated] = useState(false);

    const functionTrigger = async() => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/api/create-subscription`, {
                paymentIntent: paymentIntent,
                customerId: customerId,
                package: selectedPackage,
                coupon: coupon
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.log("err", error);
            console.error('Failed to create payment:', error.response ? error.response.statusText : error.message);
        }
        setSubscriptionCreated(true);
    }

    // useEffect(() => {
    //     if (!subscriptionCreated) {
            

    //     }
    // }, [subscriptionCreated, paymentIntent, customerId, coupon]);

    return (
        <div onClick={functionTrigger}>Success</div>
    );
}

export default Success;
