import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, selectItems } from '../../slices/cartSlice';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import arrow from '../../Assets/Images/brown-arrow.png'

export const SecondPage = ({imgToggle,imageShow, selectPackageRef}) => {

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const selectedPackage = useSelector(selectItems)
  const [ranges, setRanges] = useState([])
  const [fee, setFee] = useState("")
  const [isAnalyticsChecked, setIsAnalyticsChecked] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addons, setAddons] = useState([])
  const dispatch = useDispatch();
  const [trump, setTrump] = useState("");
  const [selectedRange, setSelectedRange] = useState(0);
  const [monthlyBtn, setMonthlyBtn] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedSecond, setIsExpandedSecond] = useState(false);
  const [platformsConfirm, setPlatformsConfirm] = useState(false)
  const [rangeConfirm, setRangeConfirm] = useState(false)
  const [trumpConfirm, setTrumpConfirm] = useState(false)
  const [finalConfirm, setFinalConfirm] = useState(false)
  const [Images, setImages] = useState(
    [
      {
        package_name: "PPC Package", ImagesLlist: [
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/670fd24b8e930339d88cff9e_1718963241209.google_ads_logo_icon%201.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/670fd24b8dd57f51a6260339_microsoft-ads-logo%201.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/670fd24b875bfafd1d454ecd_new-youtube-logo%201.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/6711458ab20d340e962329d0_amazon-512%201.png"

        ]
      },

      {
        package_name: "Social Package", ImagesLlist: [
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/6711458a9ba7a5fba9210539_Meta-Logo.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145896d7c2e0eb0ab4e81_52-522810_1048-x-550-19-snapchat-ads-logo-png.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671147cd1524cef03699f7a9_LinkedIn-Logo.wine.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/67114589288cd64e56c8cb8e_Pinterest_logo.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/6711458935b975b11c57810e_X_icon_2.svg.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671686128944602fbe17b099_social-media%20(2).png",

        ]
      },


      {
        package_name: "Programmatic Package", ImagesLlist: [
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e522df29616f269fdf_3919824.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e506dfd333ebd8245d_8329470.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e5c6c471476263eddb_2225522-200.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e5f00a4fecf6f5693c_3859247.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/67114581db783f4350b330c4_b8b9bc7a1efd0af75ce2ccf1ca3c8145%201.png",
        ]
      },

      {
        package_name: "All-In-One Bundle", ImagesLlist: [
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/670fd24b8e930339d88cff9e_1718963241209.google_ads_logo_icon%201.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/670fd24b8dd57f51a6260339_microsoft-ads-logo%201.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/670fd24b875bfafd1d454ecd_new-youtube-logo%201.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/6711458ab20d340e962329d0_amazon-512%201.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/6711458a9ba7a5fba9210539_Meta-Logo.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145896d7c2e0eb0ab4e81_52-522810_1048-x-550-19-snapchat-ads-logo-png.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671147cd1524cef03699f7a9_LinkedIn-Logo.wine.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/67114589288cd64e56c8cb8e_Pinterest_logo.png",



          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/6711458935b975b11c57810e_X_icon_2.svg.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671686128944602fbe17b099_social-media%20(2).png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e522df29616f269fdf_3919824.png",
          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e506dfd333ebd8245d_8329470.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e5c6c471476263eddb_2225522-200.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/671145e5f00a4fecf6f5693c_3859247.png",

          "https://cdn.prod.website-files.com/66f96f2d5ab822134ae92075/67114581db783f4350b330c4_b8b9bc7a1efd0af75ce2ccf1ca3c8145%201.png",

        ]
      },
    ])
  //temp changes
  const trumpRef = useRef(null);
  const addonsRef = useRef(null);
  const rangeRef = useRef(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    let allPackages = JSON.parse(localStorage.getItem("packages"));
    if (allPackages?.length > 0) {
      let getPackagePlatforms = allPackages.filter((pkg) => pkg.package_name === selectedPackage[0]?.package_name)
      setPlatforms(getPackagePlatforms[0]?.platforms)
      setRanges(getPackagePlatforms)
    }

    getAddons()
  }, [selectedPackage[0]?.package_name])

  useEffect(() => {
    let allPackages = JSON.parse(localStorage.getItem('packages'));
    if (allPackages?.length > 0) {
      let getPackagePlatforms = allPackages.filter(
        (pkg) => pkg.package_name === selectedPackage[0]?.package_name
      );
      setPlatforms(getPackagePlatforms[0]?.platforms);
      setRanges(getPackagePlatforms);
    }

    getAddons();

    // Close dropdown on outside click
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedPackage, selectedPackage[0]?.package_name]);



  // Toggle for the first item
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  // Toggle for the second item
  const toggleTextSecond = () => {
    setIsExpandedSecond(!isExpandedSecond);
  };

  useEffect(() => {
    console.log("NOT RUNNING")
    setSelectedRange(0);
    let allPackages = JSON.parse(localStorage.getItem('packages'));
    let getPackagePlatforms = allPackages?.filter(
      (pkg) => pkg?.package_name === selectedPackage[0]?.package_name
    );
    if (getPackagePlatforms?.length > 0) {
      dispatch(
        addToCart({
          package_range: 0,
          package_fee: getPackagePlatforms?.[0]?.monthlyRetainer[parseInt(0)]?.monthly_retainer,
        })
      );
    }
    setMonthlyBtn(true)
    setTrump("")
    dispatch(addToCart({
      trump: "Monthly",
      trumpPrice: "$0.00"
    }))
    setSelectedAddons([])
    setPlatformsConfirm(false)
    setRangeConfirm(false)
    setTrumpConfirm(false)
    setFinalConfirm(false)
  }, [selectedPackage[0]?.package_name])

  const handleRangeChange = (e) => {
    const value = parseInt(e.target.value);
    setSelectedRange(value);

    let allPackages = JSON.parse(localStorage.getItem('packages'));
    let getPackagePlatforms = allPackages?.filter(
      (pkg) => pkg?.package_name === selectedPackage[0]?.package_name
    );
    let rangeIndex = getPackagePlatforms[0]?.adSpendRange?.filter((rng) => rng.ad_spend_range == e.target.value)
    dispatch(
      addToCart({
        package_range: e.target.value,
        package_fee: getPackagePlatforms[0]?.monthlyRetainer[parseInt(e.target.value)]?.monthly_retainer,
      })
    );
  };

  // Format function for the range slider

  const getAddons = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/api/getAddons`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      localStorage.setItem('addons', JSON.stringify(res?.data?.addons));
      setAddons(res?.data?.addons);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckboxChange = (addonName) => {
    let updatedAddons;

    if (selectedAddons.includes(addonName)) {
      updatedAddons = selectedAddons.filter((name) => name !== addonName);
    } else {
      updatedAddons = [...selectedAddons, addonName];
    }
    setSelectedAddons(updatedAddons);

    dispatch(
      addToCart({
        selectedAddons: updatedAddons,
      })
    );
  };

  useEffect(() => {
    if (selectedPackage?.length == 1) {
      setSelectedOptions(null);
    }
  }, [selectedPackage, selectedPackage?.length])


  const handleAccountManager = async (addon, price) => {
    setMonthlyBtn(false)
    setTrump(addon);
    dispatch(addToCart({
      trump: addon,
      trumpPrice: price
    }))
  }

  const handleMonthlyBtn = (bool) => {
    setMonthlyBtn(true)
    setTrump("")
    dispatch(addToCart({
      trump: "Monthly",
      trumpPrice: "$0.00"
    }))
  }

  const handleFinalConfirm = (isConfirm) => {
    if(!isConfirm){
      trumpRef.current?.scrollIntoView({ behavior: 'smooth' });
    }else{
      console.log("SELECTED PACKAGE REF:::")
      selectPackageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setFinalConfirm(isConfirm)
    setTrumpConfirm(isConfirm)
    dispatch(addToCart({
      finalConfirm: isConfirm
    }))
  }

  const handleTrump = (isConfirm) => {
    setTrumpConfirm(isConfirm)
    if(isConfirm){
      console.log("IS CONFIRMED:: ", isConfirm)
      addonsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }else{
      setRangeConfirm(false)
      dropdownRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (rangeConfirm) {
      trumpRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [rangeConfirm]);
  useEffect(() => {
    if (trumpConfirm) {
      addonsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [trumpConfirm]);

  useEffect(() => {
    if (!finalConfirm) {
      console.log("finalConfirm: ", finalConfirm)
      trumpRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [finalConfirm]);

  return (
    <div className="text-white w-full">
      {/* Select Platforms */}
      <div className="py-6 text-white">
        <h1
          className="mr-auto text-white leading-normal font-semibold text-[30px] cursor-pointer "
          onClick={() => {
            imgToggle();
            console.log(imageShow);
          }}
        >
          {selectedPackage[0]?.package_name} <span className="mr-2 text-[#C29264] hidden md:inline-block ">✔</span>
        </h1>

        <div
          className={`transition-all duration-1000 ease-in-out overflow-hidden ${
            imageShow ? 'max-h-[1000px]' : 'max-h-0'
          }`}
        >
          <p className='mb-6'>Managed Platforms Include:</p>
          <ul
            className={`mb-5 grid gap-2 items-center`}
          >
            {Images.find(
              (pkg) => pkg.package_name === selectedPackage[0]?.package_name
            )?.ImagesLlist && (
              <div className="flex flex-wrap gap-4 justify-center">
                  <ul className="w-[80%] m-auto md:w-full  flex flex-wrap gap-4 justify-center">
                      {Images.find(
                          (pkg) => pkg.package_name === selectedPackage[0]?.package_name
                      )
                          ?.ImagesLlist
                          .map((image, index) => (
                              <li
                                  key={index + 1000}
                                  className="flex items-center text-[20px] leading-[28px] font-normal"
                              >
                                  <img
                                      src={image}
                                      alt={`image-${index}`}
                                      className="h-8 w-auto"
                                  />
                              </li>
                          ))}
                  </ul>

              </div>
            )}
          </ul>
          <div className="flex space-x-2 mt-3">
            <button onClick={()=>{
              setPlatformsConfirm(false)
              imgToggle()
              }} className="bg-transparent text-color border border-white font-semibold py-2 px-4 rounded">
              Back
            </button>
            <button onClick={()=>{
              setPlatformsConfirm(true)
              imgToggle()
              }} className="bg-[#B18659] hover:bg-[#B18659] text-white font-semibold py-2 px-4 rounded">
              Confirm
            </button>
          </div>
        </div>
      </div>
      {(selectedPackage[0]?.package_name && !platformsConfirm) ? <img className='absolute top-[380px] right-[-30px] z-50 hidden lg:block' src={arrow} height={100} width={100}/> : null}

      {platformsConfirm && (
        <div id='rangeSelector' className="mb-6 relative" ref={dropdownRef}>
          <label className="block font-semibold text-[26px] leading-[28px] mb-5">
          Select Monthly Ad Spend Range:
          </label>

          <div className="z-10 w-full overflow-visible bg-white text-black border border-gray-300 rounded-lg mt-2 shadow-lg  p-3 pt-5 px-8">
            <div className="flex flex-col mx-auto w-full">
              <div className="range-slider-container min-h-[80px]">
                {/* Range Slider */}
                <div className="relative w-full">
                  <input
                    type="range"
                    min="0"
                    max={ranges[0]?.adSpendRange?.length - 1}
                    step="1"
                    value={selectedRange}
                    onChange={handleRangeChange}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer mt-[30px] custom-range"
                  />
                  <style jsx>{`
                    /* For WebKit browsers (Chrome, Safari, etc.) */
                    .custom-range::-webkit-slider-thumb {
                      appearance: none;
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      background-color: #C79860; /* Change this to any color */
                      cursor: pointer;
                    }
                      .trustpilot-widget{
                          position: absolute !important;
                      }
                  `}</style>




                  {/* Range Steps (Points) */}
                  <div className="absolute flex top-[40px] justify-between z-0 w-full px-[10px]">
                    {ranges[0]?.adSpendRange?.map((_, index) => (
                      <span
                        key={index}
                        className={`h-[8px] w-[1px] rounded-full bg-[#C79860]`}
                        style={{ transform: 'translateX(-50%)' }}
                      />
                    ))}
                    <span
                      className={`h-[8px] w-[1px] rounded-full bg-[#C79860]`}
                      style={{ transform: 'translateX(-50%)' }}
                    />
                  </div>



                  {/* Tooltip inside blue circle */}
                  <div
                    className="absolute text-[14px] h-[20px] top-[-12px] left-0 text-center font-semibold bg-[#C79860] min-w-max text-white px-4 py-4 rounded-full w-[40px]  flex justify-center items-center transition-all"
                    style={{
                      left: `calc(${(selectedRange / (ranges[0]?.adSpendRange.length)) * 100}% - 30px)`, // Centers the tooltip above the thumb
                    }}
                  >
                    {
                      ranges[0]?.adSpendRange[selectedRange]?.ad_spend_range
                        .split(' - ') // Split the range by ' - '
                        .map(value => {
                          // Capture the number and check if it includes a '+'
                          const num = Number(value.replace(/[^0-9]/g, '')); // Extract the numeric part
                          const hasPlus = value.includes('+'); // Check if there's a '+' symbol in the value
                          return num ? `$${num.toLocaleString()}${hasPlus ? '+' : ''}` : ''; // Add back the '+' if it was present
                        })
                        .join(' - ') // Join the formatted values with ' - '
                    }

                  </div>
                </div>
                <div>
                  <h1 className='text-[22px] font-bold pt-3'>Management fee: <span className='text-[22px] font-thin pt-3'>{ranges[0]?.monthlyRetainer[selectedRange]?.monthly_retainer} <small>per month</small></span></h1>
                </div>
              </div>
            </div>
          </div>
          {!rangeConfirm && (
            <div className="flex space-x-2 mt-3">
              <button onClick={()=>{
                imgToggle()
                setPlatformsConfirm(false)
                setRangeConfirm(false)}} className="bg-transparent text-color border border-white font-semibold py-2 px-4 rounded">
                Back
              </button>
              <button onClick={() => setRangeConfirm(true)} className="bg-[#B18659] hover:bg-[#B18659] text-white font-semibold py-2 px-4 rounded">
                Confirm
              </button>
            </div>
          )}
          
          {(selectedPackage[0]?.package_name && !rangeConfirm ) ? <img className='absolute top-[100px] right-[-110px] z-50 hidden lg:block' src={arrow} height={100} width={100}/> : null}

        </div>
      )}


      {/* Payment Trump */}
      {rangeConfirm && (
        <div id='trumpOptions' className=" py-6 text-white">
          <div className="flex justify-between">
            <h1 ref={trumpRef} className="font-semibold text-[24px] leading-[30px]">
              How Often Would You Like To Meet With Your Account Manager?
            </h1>
          </div>

          <div className="grid grid-cols-1 gap-4 pb-6 mt-4">

            <button
              className={`relative items-center p-3 py-1 md:py-3 pl-[60px] rounded-xl text-[14px] md:text-[20px] text-white font-normal flex justify-between ${monthlyBtn
                ? "bg-[#C29264]"
                : "bg-[#192c47] border border-white"
                }`}
              onClick={() => handleMonthlyBtn(true)}
            >
              <div className="flex items-center absolute left-4 md:top-4  top-[0.4rem]">
                <input
                  type="radio"
                  id="monthly-id" // Updated ID for Monthly option
                  name="account-option"
                  checked={monthlyBtn}
                  className="hidden peer"
                  onChange={() => handleMonthlyBtn(true)}
                />
                <label
                  htmlFor="monthly-id" // Corrected htmlFor to match the input ID
                  className="flex items-center cursor-pointer space-x-2 peer-checked:text-[#0D1E32]"
                >
                  <span className="w-4 h-4 md:w-5 md:h-5 border-2 border-gray-600 rounded-full inline-block transition-transform duration-300 peer-hover:scale-125 peer-checked:bg-[#0D1E32] peer-checked:shadow-[inset_0_0_0_4px_white]"></span>
                </label>
              </div>

              <p>Monthly</p>
              <p className='font-light italic text-sm md:text-base'>Included in all packages</p>
            </button>
            {addons
              ?.filter((addon) => addon?.package_name === selectedPackage[0]?.package_name)[0]
              ?.addons?.filter(
                (excAddon) =>
                  excAddon?.addons === "Bi-Weekly Meetings" ||
                  excAddon?.addons === "Weekly Meetings"
              )
              ?.map((addon, index) => {
                // Find the index of the current addon in the original addons array
                const originalAddonIndex = addons
                  ?.filter((addon) => addon?.package_name === selectedPackage[0]?.package_name)[0]
                  ?.addons?.findIndex((originalAddon) => originalAddon.addons === addon.addons);

                // Now use the correct index to get the corresponding additionalFee
                let addonPrice = addons?.filter(
                  (addon) => addon?.package_name === selectedPackage[0]?.package_name
                )[0]?.additionalFee[originalAddonIndex]?.additionalFee;

                return (
                  <button
                    key={index}
                    className={`relative p-3 py-1 md:py-3 pl-[60px] rounded-xl text-[14px] md:text-[20px] text-white font-normal flex justify-between ${trump === addon?.addons
                      ? "bg-[#C29264]"
                      : "bg-[#192c47] border border-white"
                      }`}
                    onClick={() => handleAccountManager(addon?.addons, addonPrice)}
                  >
                    <div className="flex items-center absolute left-4 md:top-4 top-[0.4rem]">
                      <input
                        type="radio"
                        id={`addon-${index}`} // Unique ID for each addon
                        name="account-option"
                        checked={trump === addon?.addons}
                        className="hidden peer"
                        onChange={() => handleAccountManager(addon?.addons, addonPrice)}
                      />
                      <label
                        htmlFor={`addon-${index}`}
                        className="flex items-center cursor-pointer space-x-2 peer-checked:text-[#0D1E32]"
                      >
                        <span className="w-4 h-4 md:w-5 md:h-5 border-2 border-gray-600 rounded-full inline-block transition-transform duration-300 peer-hover:scale-125 peer-checked:bg-[#0D1E32] peer-checked:shadow-[inset_0_0_0_4px_white]"></span>
                      </label>
                    </div>

                    <p>{addon?.addons}</p>
                    <p>{addonPrice} <small className='font-light text-sm md:text-base'>/per month</small></p>
                  </button>
                );
              })}
          </div>

          {!trumpConfirm && (
            <div className="flex space-x-2">
              <button onClick={()=>handleTrump(false)} className="bg-transparent text-color border border-white font-semibold py-2 px-4 rounded">
                Back
              </button>
              <button onClick={()=>handleTrump(true)} className="bg-[#B18659] hover:bg-[#B18659] text-white font-semibold py-2 px-4 rounded">
                Confirm
              </button>
            </div>
          )}
          
          {(selectedPackage[0]?.package_name && !trumpConfirm && rangeConfirm ) ? <img className='absolute top-[700px] right-[-40px] z-50 hidden lg:block' src={arrow} height={100} width={100}/> : null}

        </div>
      )}
      
      {trumpConfirm && (
        <div  id='addons' className="bg-[#0D1E32] py-6 text-white">
        {/* Optional Add-Ons */}
          <div className="mb-6">
            <p ref={addonsRef} className="block font-semibold text-[26px] leading-[28px] mb-5">Optional Add-On's</p>
            {addons
              ?.filter(
                (addon) => addon?.package_name === selectedPackage[0]?.package_name
              )[0]
              ?.addons?.map((addon, index) => {
                let addonPrice = addons?.filter(
                  (addon) => addon?.package_name === selectedPackage[0]?.package_name
                )[0]?.additionalFee[index]?.additionalFee;
                let addonPaymentType = addons?.filter(
                  (addon) => addon?.package_name === selectedPackage[0]?.package_name
                )[0]?.paymentType[index]?.paymentType;

                return (
                  <>
                    {addon?.addons !== "Bi-Weekly Meetings" && addon?.addons !== "Weekly Meetings" && (
                      <div className="mt-4" key={index}>
                        <label htmlFor={`addon-checkbox-${index}`}>
                          <div
                            className={`flex items-start p-4 rounded-lg cursor-pointer ${
                              selectedAddons.some((selectedAddon) => selectedAddon === addon?.addons) ? 'bg-white' : 'bg-[#192D41]'
                            }`}
                          >
                            <input
                              type="checkbox"
                              id={`addon-checkbox-${index}`}
                              className="form-checkbox h-5 w-5 text-[#C79860] mr-4 mt-1 hover:cursor-pointer"
                              checked={selectedAddons.some((selectedAddon) => selectedAddon === addon?.addons)}
                              onChange={() => handleCheckboxChange(addon?.addons, addonPrice)}
                            />
                            <div className={`${selectedAddons?.some((selectedAddon) => selectedAddon === addon?.addons) ? "text-[#192D41]" : "text-white"}`}>
                              <p className="text-[18px] leading-[28px] font-normal">
                                {addonPrice ? `${addonPrice}` : "$0"} - {addon?.addons} ({addonPaymentType})
                              </p>
                              <p className={`text-[14px] leading-[28px] font-normal`}>
                                {addon?.addons === "Integrated Analytics Package" ? (
                                  <>
                                    <span className={`text-[14px] leading-[28px] font-normal ${isExpanded ? '' : 'line-clamp-3'}`}>
                                      (Recommended: If you're not accurately tracking conversions, sales, and purchases in ad platforms, you need this!).<br />
                                      Unlock the full potential of your data with our Integrated Analytics Package.<br /> This comprehensive solution provides real-time insights into campaign performance, user behavior, and ROI metrics, helping you make data-driven decisions with ease. Track key performance indicators, optimize strategies, and maximize the impact of your marketing efforts. Take control of your analytics today and turn insights into action.
                                    </span>
                                    <button className="text-blue-500 block mt-2" onClick={toggleText}>
                                      {isExpanded ? 'Show Less' : 'Show More'}
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <span className={`text-[14px] leading-[28px] font-normal ${isExpandedSecond ? '' : 'line-clamp-3'}`}>
                                      (Recommended: If you have no website, a slow website, or are sending traffic just to your homepage, this is for you!).<br />
                                      Maximize conversions with a high-performing, professionally designed Advertising Landing Page.<br /> Our custom-built landing pages are optimized for speed, mobile responsiveness, and seamless user experience, driving more qualified leads to your business. Capture attention, highlight your value propositions, and ensure that every visitor is one step closer to becoming a loyal customer. Elevate your advertising with a page designed to convert.
                                    </span>
                                    <button className="text-blue-500 block mt-2" onClick={toggleTextSecond}>
                                      {isExpandedSecond ? 'Show Less' : 'Show More'}
                                    </button>
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                    )}
                  </>
                );
                
              })}
            <div className="flex space-x-2 mt-3">
              {selectedPackage?.find((itm) => itm?.finalConfirm)?.finalConfirm ? (
                <button onClick={()=>handleFinalConfirm(true)} className=" hover:bg-[#B18659] bg-transparent text-color border border-white font-semibold py-2 px-4 rounded">
                  Confirmed
                </button>
              ) : (
                <button onClick={()=>handleFinalConfirm(true)} className="bg-[#B18659] hover:bg-[#B18659] text-white font-semibold py-2 px-4 rounded">
                  Confirm Package
                </button>
              )}
              
            </div>

          </div>
          {selectedPackage?.find((itm) => itm?.finalConfirm)?.finalConfirm ? null : (
            <>
              {(selectedPackage[0]?.package_name && trumpConfirm ) ? <img className='absolute top-[1100px] right-[-40px] z-50 hidden lg:block' src={arrow} height={100} width={100}/> : null}
            </>
          )}

        </div>
      )}
    </div>
  );
};
