import React, { useEffect, useRef, useState } from "react";
import logo from "../src/Assets/Images/Paymentform logo.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";

import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import { PaymentForm } from "./components/Payment form/PaymentForm.jsx";
import { FirstPage } from "./components/Payment form/FirstPage.jsx";
import { SecondPage } from "./components/Payment form/SecondPage.jsx";
import { useDispatch } from "react-redux";
import { addToCart, clearCart } from "./slices/cartSlice";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentDetail = () => {
  // State to track the selected package
  const [selectedPackage, setSelectedPackage] = useState("Starter Package");
  const [choosePackage, setChoosePackage] = useState("PPC package");
  const [pageToggle, setPageToggle] = useState(true);
  const [pkgSelect, setpkgSelect] = useState(false);
  const [planselected, setPlanSelected] = useState("");
  const [selectedIncentive, setSelectedIncentive] = useState("Performance-1");
  const [activeButton, setActiveButton] = useState("Monthly");
  const [trump, setTrump] = useState("PAY_UPFRONT");
  const [prices, setPrices] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0)
  const [discount, setDiscount] = useState()
  const [customerResponse, setCustomerResponse] = useState()
  const [clientSecret, setClientSecret] = useState("");
  const dispatch = useDispatch()
  const [packages, setPackages] = useState([]);

  const createCustomer = async() => {
    try{
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/api/create-dummy-customer`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("customer", JSON.stringify(res?.data?.customer));
      setCustomerResponse(res?.data?.customer);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/api/create-payment-intent`,
          {
            package: selectedPackage,
            customer: res?.data?.customer?.id,
            strategy: trump
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          setClientSecret(response.data.clientSecret);
        })
        .catch((error) => {
          console.error("Error fetching client secret:", error);
        });
    }catch(err){
      console.log("ERR: ", err)
    }
  }
  useEffect(()=> {
    createCustomer()
  }, [])

  const pageToggleChange = () => {
    setPageToggle(!pageToggle);
  };


  const getPrices = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/api/getPrices`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setPrices(res?.data?.prices);
      setAddOns(res?.data?.addOns);
    } catch (error) {
      toast.error("Prices not found!");
    }
  };

  const getPackages = async () => {
    handlePackage("All-In-One Bundle")
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/api/getPackages`, {
      headers: { "Content-Type": "application/json" }
    });
    localStorage.setItem("packages", JSON.stringify(res?.data?.packages));
    setPackages(res?.data?.packages);
  };

  useEffect(()=> {
    getPrices();
    getPackages()
  }, [])

    const handlePackage = (pkgName) => {
      dispatch(clearCart())
      dispatch(addToCart({
        package_name: pkgName,
      }))
      setChoosePackage(pkgName);
    };

    const appearance = {
      theme: "stripe",
    };

    const options = {
      clientSecret,
      appearance,
      paymentMethodCreation: "manual",
    };
    const selectPackageRef = useRef(null);

  return (
      <div className="min-h-screen poppins flex flex-wrap ">
          {pkgSelect ?
            <div className="relative lg:w-1/2 w-full bg-[#0d1e32] pr-5 md:pr-12 lg:pr-20 pl-5 md:pl-20 lg:pl-32 pb-10 pt-20">
              {!pageToggle && (
                <button className="absolute text-white top-3 left-3 md:left-10">
                  <IoIosArrowRoundBack
                    className=" text-[70px]"
                    onClick={pageToggleChange}
                  />
                </button>
              )}

              
            </div>
            :
            <div className="relative lg:w-1/2 w-full bg-[#0d1e32] pr-5 md:pr-12 lg:pr-20 pl-5 md:pl-20 lg:pl-32 pb-48 md:pb-10 pt-20">
              {/* Package section */}

              <FirstPage
                choosePackage={choosePackage}
                handlePackage={handlePackage}
                setpkgSelect={setpkgSelect}
                pkgSelect={pkgSelect}
                packages={packages}
                selectPackageRef={selectPackageRef}
              />
              
            </div>
          }

          <div className="z-0 lg:w-1/2 w-full pr-5 md:pr-12 lg:pr-20 pl-5 md:pl-20 lg:pl-32 pb-10 pt-32 bg-white relative">
          <img
                src={logo}
                alt="BANEDIGITAL Logo"
                className="absolute h-auto float-end w-56 md:w-96  top-0 md:top-[-20px] right-7" // Adjust width and height as needed
              />
            <PaymentForm
              discount={discount}
              finalPrice={finalPrice}
              clientSecret={clientSecret}
              Elements={Elements}
              options={options}
              stripePromise={stripePromise}
              CheckoutForm={CheckoutForm}
              customerResponse={customerResponse}
              logo={logo}
              selectPackageRef={selectPackageRef}
            />
          </div>
      </div>
  );
};

export default PaymentDetail;
