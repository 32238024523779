import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const getCartTotal = (item) =>
  item?.reduce((accumulator, currVal) => currVal.price + accumulator, 0);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // Check if an item exists in `items` where any key of `action.payload` matches
      
      const existingIndex = state.items.findIndex((item) =>
        Object.keys(action.payload).some(
          (key) => key in item
        )
      );

      if (existingIndex >= 0) {
        // Merge the new object into the existing one, updating keys
        state.items[existingIndex] = {
          ...state.items[existingIndex],  // Keep old values
          ...action.payload,              // Overwrite with new values
        };
      } else {
        // If no matching key found, add the new object to `items`
        state.items.push(action.payload);
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { addToCart, removeFromCart, showProduct, addGrade, clearCart } = cartSlice.actions;

// Selectors - This is how we pull information from the Global store slice
export const selectItems = (state) => state.cart.items;

export default cartSlice.reducer;
